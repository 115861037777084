<script>
import opticsList from "@/optics.json";
import translations from "@/translations.json";

export default{
    props: {
        city: {
            type: String,
            default: '',
        }
    },
    emits: [
        'changeNetwork',
        'changeAddress',
        'scrollToBlock',
    ],
    data() {
        return {
            network: null,
            address: null,
            optics: [],
            translationsArr: [],
            networks: [],
            addresses: [],
        }
    },
    watch: {
        'city'(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.networks = this.optics[newValue]['network'];
                this.network = null;
                this.address = null;
            }
        },
        'network'(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (this.network !== null) {
                    this.addresses = this.optics[this.city]['network'][newValue]['addresses'];
                } else {
                    this.addresses = [];
                    this.network = newValue;
                }
                this.address = null;
            }
        },
        '$store.state.language'(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.optics = opticsList[newValue];
                this.translationsArr = translations[newValue];

                this.networks = this.optics[this.city]['network'];
                if (this.network) {
                    this.addresses = this.optics[this.city]['network'][this.network]['addresses'];
                }
            }
        }
    },
    methods: {
        changeNetwork(event) {
            this.network = event.target.value;
            this.$emit('changeNetwork', this.network);
        },
        changeAddress(event) {
            this.address = event.target.value;
            this.$emit('changeAddress', this.address);
            this.$emit('scrollToBlock');
        },
        sortData(obj) {
            if (Array.isArray(obj)) {
                return obj.map(this.sortData);
            } else if (obj !== null && typeof obj === 'object') {
                return Object.keys(obj)
                    .sort()
                    .reduce((acc, key) => {
                        acc[key] = this.sortData(obj[key]);
                        return acc;
                    }, {});
            }
            return obj;
        },
    },
    mounted() {
        this.optics = opticsList[this.$store.state.language];
        this.translationsArr = translations[this.$store.state.language];

        this.networks = this.optics[this.city]['network'];
    },
}
</script>

<template>
    <div class="flex flex-col w-full gap-[30px]">
        <div class="flex bg-white w-full rounded-[50px] px-[30px] h-[59px]">
            <select @change="changeNetwork($event)" name="network" id="network" class="focus-within:outline-0 w-full bg-[white] text-[24px] text-[#223758] focus-visible:outline-0">
                <option class="text-[#223758] text-[16px] md:text-[20px] font-medium" value=""
                        :selected="!network" disabled :hidden="!network">
                    {{ translationsArr.network_placeholder }}
                </option>
                <option :id="key" class="text-[#223758] text-[16px] md:text-[20px] font-medium" :value="key" :key="key"
                        v-for="(value, key) in networks">
                    {{ value.name }}
                </option>
            </select>
        </div>

        <div v-if="network" class="flex bg-white w-full rounded-[50px] px-[30px] h-[59px]">
            <select @change="changeAddress($event)" name="address" id="address" class="focus-within:outline-0 w-full bg-[white] text-[24px] text-[#223758] focus-visible:outline-0">
                <option class="text-[#223758] text-[16px] md:text-[20px] font-medium" value=""
                        :selected="!address" disabled :hidden="!address">
                    {{ translationsArr.address_placeholder }}
                </option>
                <option :id="key" class="text-[#223758] text-[16px] md:text-[20px] font-medium" :value="key" :key="key"
                        v-for="(value, key) in addresses">
                    {{ value.name }}
                </option>
            </select>
        </div>
    </div>
</template>