<script>
import opticsList from "@/optics.json";
import translations from "@/translations.json";
import {ref} from "vue";

export default{
    props: {
        city: {
            type: String,
            default: '',
        }
    },
    emits: [
        'changeNetwork',
        'changeAddress',
        'scrollToBlock',
        'changeAddressN',
    ],
    data() {
        return {
            network: ref(null),
            address: ref(null),
            optics: ref([]),
            translationsArr: ref([]),
            networks: ref([]),
            addresses: ref({}),
        }
    },
    watch: {
        'city'(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.address = null;
                this.network = null;
                this.mountedHandler();
            }
        },
        '$store.state.language'(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.mountedHandler();

                if (this.address) {
                    this.networks = this.addresses[this.address]['optics'];
                }
            }
        }
    },
    methods: {
        changeAddress(event) {
            this.address = event.target.value;

            this.networks = this.addresses[this.address]['optics'];
            Object.entries(this.networks).forEach((el) => {
                this.network = el[1].name;
            })
            this.$emit('changeNetwork', this.network);
            this.$emit('changeAddress', this.address);
        },
        sortData(obj) {
            if (Array.isArray(obj)) {
                return obj.map(this.sortData);
            } else if (obj !== null && typeof obj === 'object') {
                return Object.keys(obj)
                    .sort()
                    .reduce((acc, key) => {
                        acc[key] = this.sortData(obj[key]);
                        return acc;
                    }, {});
            }
            return obj;
        },
        mountedHandler() {
            this.addresses = {};

            this.optics = opticsList[this.$store.state.language];
            this.translationsArr = translations[this.$store.state.language];

            Object.entries(this.optics[this.city].network).forEach(([key, value]) => {
                let optics = { [key]: { name: value.name } };

                Object.entries(value.addresses).forEach(([addressSystem, item]) => {
                    this.addresses[addressSystem] = {
                        name: item.name,
                        optics: optics,
                    };
                });
            });
        }
    },
    mounted() {
        this.mountedHandler();
    },
}
</script>

<template>
    <div class="flex flex-col w-full gap-[30px]">
        <div class="flex bg-white w-full rounded-[50px] px-[30px] h-[59px]">
            <select @change="changeAddress($event)" name="address" id="address" class="focus-within:outline-0 w-full bg-[white] text-[24px] text-[#223758] focus-visible:outline-0">
                <option class="text-[#223758] text-[16px] md:text-[20px] font-medium" value=""
                        :selected="!address" disabled :hidden="!address">
                    {{ translationsArr.address_placeholder }}
                </option>
                <option :id="key" class="text-[#223758] text-[16px] md:text-[20px] font-medium" :value="key" :key="key"
                        v-for="(value, key) in addresses">
                    {{ value.name }}
                </option>
            </select>
        </div>

        <div v-if="address" class="flex bg-white w-full rounded-[50px] px-[30px] h-[59px]">
            <select disabled name="network" id="network" class="focus-within:outline-0 w-full bg-[white] text-[24px] text-[#223758] focus-visible:outline-0">
<!--                <option class="text-[#223758] text-[16px] md:text-[20px] font-medium" value=""-->
<!--                        :selected="!network" disabled :hidden="!network">-->
<!--                    {{ translationsArr.network_placeholder }}-->
<!--                </option>-->
                <option :id="key" class="text-[#223758] text-[16px] md:text-[20px] font-medium" :selected="true" :value="key" :key="key"
                        v-for="(value, key) in networks">
                    {{ value.name }}
                </option>
            </select>
        </div>
    </div>
</template>